import React from 'react';

const StoryText = () => {
  return (
    <React.Fragment>
      <p>
        Hi! I am a self-taught developer with a passion for problem solving, building, and
        designing. Since the first day I started coding, I was hooked and realized this was what I
        wanted to dedicate my career towards. Learning new technologies is always exciting and
        something I look forward to as I continue to develop my skills. And I am excited by the
        prospect of working on amazing products that will continue to enrich peoples’ lives long
        after they are built. So I would love to bring my passion, drive, and skills to a
        like-minded team where we can help each other grow and make a positive difference in the
        world.
      </p>
      <br />
    </React.Fragment>
  );
};

export default StoryText;
